/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "../../common/common.css";

.rbc-toolbar {
  font-size: inherit;
}

.rbc-timeslot-group {
  min-height: 80px;
}

.rbc-header {
  @apply text-left;
}

.rbc-button-link {
  @apply text-left;
}

.rbc-header.rbc-today {
}

.rbc-time-column {
  /*@apply bg-gray-50*/
}

.rbc-time-column.rbc-today {
  @apply bg-gray-50;
}

.rbc-header.rbc-today {
  @apply bg-gray-50;
}

.rbc-today .rbc-time-slot {
  @apply border-gray-100;
}

.rbc-current-time-indicator {
  height: 2px;
  @apply bg-secondary;
}

.rbc-time-content {
  border-top-width: 1px;
}

.rbc-event {
  border-width: 0 !important;
  @apply p-0 bg-primary-200 rounded-sm hover:bg-primary-300;
}

.rbc-event.rbc-selected {
  @apply bg-primary-300;
}

/*.rbc-time-column {*/
/*    @apply bg-gray-50*/
/*}*/

.rbc-allday-cell {
  display: none;
}
.rbc-time-view .rbc-header {
  border-bottom: none;
}
