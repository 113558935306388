/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

/* Style for all headers */
.header {
  font-weight: bold;
}

/* Style for h1 headers */
.h1-header {
  font-size: 32px;
  margin-bottom: 10px;
}

/* Style for h2 headers */
.h2-header {
  font-size: 26px;
  margin-bottom: 10px;
}

/* Style for h3 headers */
.h3-header {
  font-size: 20px;
  margin-bottom: 10px;
}

.h2-header::before {
  content: "\A\A"; /* Two line breaks */
  white-space: pre;
}

.h3-header::before {
  content: "\A"; /* One line break */
  white-space: pre;
}

.custom-ul {
  list-style-type: disc !important;
  padding-left: 30px !important;
}

.custom-ol {
  list-style-type: upper-latin !important;
  padding-left: 30px !important;
}

.custom-li {
  /* Set padding for each <li> element */
  padding: 5px 0 !important;
}

.justified-text {
  text-align: justify;
}

.child-overflow {
  overflow: visible !important; /* Override the parent's overflow property */
}

.mailto-link {
  color: blue;
}
