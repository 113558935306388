/*
 * Copyright © 2023 Medaica, Inc
 *
 * All rights reserved.
 *
 * This code is confidential and proprietary information belonging to Medaica, Inc.
 * Unauthorized copying, distribution, or use of this code, in whole or in part,
 * is strictly prohibited, and may constitute a violation of intellectual property rights.
 *
 * If you have received this code in error, please notify the owner immediately
 * at support@medaica.com and delete this file from your system.
 */

@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600;700");

/*@font-face {*/
/*  font-family: "Inter";*/
/*  src: local("Inter"), url(./fonts/Inter-VariableFont_slntwght.ttf) format("truetype");*/
/*}*/

::part(region-handle-right) {
  border-right-width: 0 !important;
}

::part(region-handle-left) {
  border-left-width: 0 !important;
}

@layer components {
  html {
    @apply text-gray-900 text-sm leading-normal font-normal;
  }

  /* Used to refer to page elements such as buttons in explanatory text, e.g.

                                   For help click <strong>Help</strong>

                                 Where Help refers to a button with the text "Help"
                               */
  input {
    --tw-ring-shadow: 0 0 #000 !important;
  }

  strong {
    @apply font-semibold;
  }

  .alert-warning strong {
    @apply font-semibold text-yellow-800;
  }

  .alert-success strong {
    @apply font-semibold text-green-800;
  }

  .alert-info strong {
    @apply font-semibold text-blue-800;
  }

  .alert-error strong {
    @apply font-semibold text-red-800;
  }

  .header-color {
    @apply text-gray-900;
  }

  .page-header {
    @apply font-semibold header-color;
    font-size: 1.64rem;
  }

  .link-color {
    @apply text-primary hover:text-primary-600;
  }

  .link {
    @apply link-color hover:underline;
  }

  .main-centered {
    @apply max-w-6xl mt-10 mx-auto px-4 mb-8;
  }

  /*.disabled {*/
  /*    @apply opacity-50 pointer-events-none*/
  /*}*/
  /********** Text **********/
  .text-gray-on-gray {
    @apply text-gray-700;
  }

  .text-error {
    @apply text-red-600;
  }

  /********** Detail **********/
  .detail-label {
    @apply text-gray-500 mb-1;
  }

  .detail-detail {
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    column-gap: 1rem;
  }

  .detail-detail dt {
    @apply text-gray-500;
  }

  /********** Tables **********/
  .table {
    @apply min-w-full;
  }

  .table > .MuiToolbar-root {
    @apply border-b;
  }

  .table table {
    @apply w-full;
  }

  .table th {
    @apply bg-gray-50 border-b px-6 py-1 text-left font-medium text-gray-on-gray;
  }

  .table tbody {
    @apply divide-y;
  }

  .table tbody td {
    @apply px-6 py-4 whitespace-nowrap text-base;
  }

  .table tfoot td {
    @apply border-b-0;
  }

  /********** Panel *********/
  .panel {
    @apply overflow-hidden border rounded-lg;
  }

  .panel-title {
    @apply text-lg font-medium leading-6 header-color mb-4;
  }

  .panel-header {
    @apply bg-gray-50 px-6 py-3 text-gray-on-gray border-b;
  }

  .panel-content {
    @apply px-6 py-5 sm:p-8;
  }

  /********** Buttons *********/
  .button-primary {
    @apply text-white border bg-primary-500 hover:bg-primary-600 focus-primary disabled:opacity-50 disabled:bg-primary disabled:cursor-default;
  }

  .button-secondary {
    @apply border border-gray-300 text-gray-900 bg-white hover:bg-gray-100 disabled:opacity-50 focus-secondary disabled:bg-transparent disabled:cursor-default;
  }

  .button-text {
    @apply link-color focus font-medium disabled:opacity-50 focus-secondary disabled:bg-transparent disabled:cursor-default hover:bg-primary-100;
  }

  .button-link {
    @apply link-color focus hover:underline disabled:opacity-50 focus-secondary disabled:bg-transparent disabled:cursor-default;
  }

  .button-error-primary {
    @apply border border-red-500 bg-red-600 hover:bg-red-700 text-white focus-error disabled:opacity-50 disabled:cursor-default;
  }

  .button-warning-primary {
    @apply border border-yellow-500 bg-yellow-500 hover:bg-yellow-600 text-white focus-warning disabled:opacity-50 disabled:cursor-default;
  }

  .button-x {
    padding: 4px;
    @apply text-gray-400 focus focus-secondary hover:bg-primary-125 hover:text-gray-500 rounded-full;
  }

  /********** Focus ***********/
  /** Sizes **/
  .focus {
    @apply focus:outline-none focus:ring-4 focus:ring-offset-0;
  }

  .focus-sm {
    @apply focus:outline-none focus:ring-2 focus:ring-offset-0;
  }

  .focus-none {
    @apply focus:outline-none focus:ring-0 focus:ring-offset-0;
  }

  /** Colors **/
  .focus-primary {
    @apply focus:ring-primary-200;
  }

  .focus-secondary {
    @apply focus:ring-primary-200;
  }

  .focus-warning {
    @apply focus:ring-yellow-200;
  }

  .focus-error {
    @apply focus:ring-red-200;
  }

  /** Sizes **/
  .button-sm {
    @apply inline-flex items-center px-3 py-1.5 text-sm font-normal rounded-md focus-sm;
  }

  .button-sm svg {
    @apply h-4 w-4 mr-2;
  }

  .button-md {
    @apply inline-flex justify-center items-center px-4 py-2 leading-snug font-normal tracking-wide rounded-md focus;
  }

  .button-thin {
    @apply inline-flex items-center px-2.5 py-2 rounded-md focus;
  }

  .button-md svg {
    @apply h-4 w-4;
  }

  .button-lg {
    @apply inline-flex items-center px-4 py-2 text-lg font-normal tracking-wide rounded-md focus;
  }

  /*********** Forms **********/
  .input {
    @apply appearance-none rounded-md border border-gray-300 focus focus-primary focus:border-primary-300;
  }

  .input-error {
    @apply border-red-300 text-error placeholder-red-300 focus focus-error focus:border-red-300;
  }

  .checkbox {
    @apply focus-primary focus h-4 w-4 text-primary-600 border-gray-300
        rounded;
  }

  .checkbox-error {
    @apply border-red-300 focus-error;
  }

  /********** Exams *********/
  /* This is used to mirror video elements */
  .mirrored {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg); /* Safari and Chrome */
    -moz-transform: rotateY(180deg); /* Firefox */
  }

  .video-button {
    @apply h-14 w-14 flex justify-center items-center border border-gray-300 bg-white rounded-lg text-gray-400;
  }

  .video-button svg {
    @apply stroke-2;
  }

  /********** React datepicker *********/

  .react-datepicker__day--selected {
    @apply bg-primary-600 !important;
  }

  #date-picker-input::-webkit-calendar-picker-indicator {
    padding-left: 400px;
  }

  .custom-datepicker-input {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    cursor: pointer;
  }

  .datepicker_icon {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    cursor: pointer;
  }

  .definition-list {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 3px;
  }

  .definition-list dt {
    @apply font-medium;
  }

  /********** Inverted corners *********/
  .rl-corner:before {
    content: "";
    position: absolute;

    height: 10px;
    width: 20px;

    bottom: 0;
  }

  .rl-corner:before {
    left: -20px;

    border-radius: 0 0 6px 0;
    -moz-border-radius: 0 0 6px 0;
    -webkit-border-radius: 0 0 6px 0;

    -webkit-box-shadow: 10px 0 0 0 theme('backgroundColor.gray["50"]');
    box-shadow: 10px 0 0 0 theme('backgroundColor.gray["50"]');
    border-right: 1px solid theme('borderColor.gray["200"]');
    border-bottom: 1px solid theme('borderColor.gray["200"]');
  }

  .rb-corner:after {
    content: "";
    position: absolute;
    height: 10px;
    width: 20px;
    bottom: 0;
  }

  .rb-corner:after {
    right: -20px;
    border-radius: 0 0 0 6px;
    -moz-border-radius: 0 0 0 6px;
    -webkit-border-radius: 0 0 0 6px;
    -webkit-box-shadow: -10px 0 0 0 theme('backgroundColor.gray["50"]');
    box-shadow: -10px 0 0 0 theme('backgroundColor.gray["50"]');
    border-left: 1px solid theme('borderColor.gray["200"]');
    border-bottom: 1px solid theme('borderColor.gray["200"]');
  }

  /********** Popup arrow *********/
  .arrow {
    position: absolute;
    background-color: blue;
    margin-left: -20px;
    margin-top: -18px;
    width: 40px;
    height: 17px;
  }

  .arrow:after {
    content: " ";
    margin-top: 4px;
    margin-left: 10px;
    background-color: green;
    position: absolute;
    transform: rotate(-45deg);
    width: 20px;
    height: 20px;
    z-index: -1;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    @apply border;
  }

  .rbc-toolbar {
    font-size: inherit;
  }

  .rbc-timeslot-group {
    min-height: 80px;
  }

  .rbc-header {
    @apply text-left;
  }

  .rbc-button-link {
    @apply text-left;
  }

  .rbc-header.rbc-today {
  }

  .rbc-time-column {
    /*@apply bg-gray-50*/
  }

  .rbc-time-column.rbc-today {
    @apply bg-gray-50;
  }

  .rbc-header.rbc-today {
    @apply bg-gray-50;
  }

  .rbc-today .rbc-time-slot {
    @apply border-gray-100;
  }

  .rbc-current-time-indicator {
    height: 2px;
    @apply bg-secondary;
  }

  .rbc-time-content {
    border-top-width: 1px;
  }

  .rbc-event {
    border-width: 0 !important;
    @apply p-0 bg-primary-200 rounded-sm hover:bg-primary-300;
  }

  .rbc-event.rbc-selected {
    @apply bg-primary-300;
  }

  /*.rbc-time-column {*/
  /*    @apply bg-gray-50*/
  /*}*/
  .rbc-allday-cell {
    display: none;
  }

  .rbc-time-view .rbc-header {
    border-bottom: none;
  }

  .heart-rate-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    background-color: #4472c4;
    border-radius: 15px;
    color: #fff; /* To make the text white */
  }

  .heart-rate-value {
    font-size: 36px;
  }

  .heart-rate-label {
    font-size: 16px;
  }
}
